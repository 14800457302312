import { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import { setPopupUpcoming, setFlashUpcoming, setAnimations, setPopup, setCallbackLink, setMenu } from './store/actions/general.actions'
import { login } from './store/reducers/userReducer'

//services
import { userService } from './services/userService'

//pages
import { ErrorServer } from './pages/error-server'
import { Tickets } from './pages/tickets'
import { Download } from "./pages/download"
import {Info} from "./pages/info"
import { StreamWrapper } from "./pages/stream-wrapper"

//cmps
import { Header } from './cmps/header'
import { Footer } from './cmps/footer'
import { Menu } from "../src/cmps/menu"
import { Popup } from "./cmps/popup/popup"
import { UpperPopup } from './cmps/upper-popup'
import { PopupUpcoming } from "./cmps/upcomingPopup"
import { Accessibility } from "./pages/accessibility"
import { AccessibilityWidget } from "./cmps/accessibility-widget"

function App() {
  const dispatch = useDispatch()
  const [pages, setPages] = useState(null)
  const user = useSelector((state) => state.user)
  const timeRef = useRef(null)

  useEffect(() => {
    loadPages()
    dispatch(setAnimations(true))
    // if (user.phone) loadUser()
    // window.addEventListener('keydown', handleKeyPress)
    // if (isMobile && window.navigator.userAgent.includes('droid')) dispatch(setPopup('app'))
    // if (window.location.href.includes('65')) dispatch(setCallbackLink(window.location.href))
    return () => { clearInterval(timeRef.current) }
  }, [])

  // useEffect(() => {
  //   if (user.phone) loadCheckUpcoming()
  // }, [user.phone])

  const loadUser = async () => {
    try {
      const loadedUser = await userService.getUser()
      dispatch(login(loadedUser))
    }
    catch (err) {
      console.log(err)
    }
  }

  const loadPages = () => {
    const { Home } = require('./pages/home')
    const { Profile } = require('./pages/profile')
    const { Tickets } = require('./pages/tickets')
    const { Login } = require('./pages/login')
    const { DeleteAccount } = require('./pages/delete-account')
    const { Creator } = require('./pages/creator')
    setPages({ Home, Profile, Tickets, Login, DeleteAccount, Creator })
  }

  const loadCheckUpcoming = async () => {
    if (!user.phone || window.location.href.includes('tickets')) return
    try {
      const upcoming = await userService.getUserUpcoming(user.phone)
      setEventTimer(upcoming)
      for (let i = 0; i < upcoming.length; i++) {
        const ev = upcoming[i]
        const d = new Date(ev.date).valueOf() - Date.now()
        if (d < 300000) {
          dispatch(setFlashUpcoming(true))
          return
        }
      }
      dispatch(setFlashUpcoming(false))
    }
    catch (err) {
      console.log(err)
    }
  }

  const setEventTimer = (upcoming) => {
    clearInterval(timeRef.current)
    if (!upcoming || !upcoming.length) return
    let shortest = Infinity
    for (let i = 0; i < upcoming.length; i++) {
      const ev = upcoming[i]
      const d = new Date(ev.date).valueOf() - Date.now()
      if (d > -10000 && d < shortest) shortest = d
    }
    if (shortest < 214748364) {
      timeRef.current = setTimeout(() => dispatch(setPopupUpcoming(true)), shortest)
      window.shortest = shortest
    }
  }

  const setNewTimer = (d) => {
    if (d > window.shortest) return
    clearInterval(timeRef.current)
    timeRef.current = setTimeout(() => dispatch(setPopupUpcoming(true)), d)
    window.shortest = d
  }

  function handleKeyPress(event) {
    if (event.key === 'Escape') dispatch(setPopup(''))
    if (event.key === 'ArrowUp') window.scrollBy(0, -200)
  }

  if (!pages || !pages.Profile) return <div className="home"><div className="loader"><div></div><div></div><div></div><div></div>
    <div></div><div></div><div></div><div></div></div></div>

  return <Router>
    <Header />
    <main className='main-layout' id='main-layout'>
      <Routes>
        <Route path='/info' element={<Info />} />
        <Route path='/Stream' element={<StreamWrapper />} />
        <Route path='/error-server' element={<ErrorServer />} />
        <Route path='/delete-account' element={<pages.DeleteAccount />} />
        <Route path='/' element={<Download />} />
      </Routes>
    </main>
    <Footer />
    <Menu />
    <Popup />
    <UpperPopup />
    <PopupUpcoming />
    <AccessibilityWidget />
  </Router>

  // return <Router>
  //   <Header />
  //   <main className='main-layout' id='main-layout'>
  //     <Routes>
  //       <Route path='/profile' element={<pages.Profile />} />
  //       <Route path='/tickets' element={<Tickets />} />
  //       <Route path='/error-server' element={<ErrorServer />} />
  //       <Route path='/accessibility' element={<Accessibility />} />
  //       <Route path='/login' element={<pages.Login />} />
  //       <Route path='/delete-account' element={<pages.DeleteAccount />} />
  //       <Route path='/creator/:id' element={<pages.Creator />} />
  //       <Route path='/show/:id' element={<pages.Home setNewTimer={setNewTimer} />} />
  //       <Route path='/' element={<pages.Home setNewTimer={setNewTimer} />} />
  //     </Routes>
  //   </main>
  //   <Footer user={user} />
  //   <Menu />
  //   <Popup />
  //   <UpperPopup />
  //   <PopupUpcoming />
  //   <AccessibilityWidget />
  // </Router>
}

export default App