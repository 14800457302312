import { Stream } from "./stream"
import { Error } from "./error"
import { showService } from "../services/showService"
import { useEffect, useState } from "react"

export function StreamWrapper() {

    const [status, setStatus] = useState('loading') // loading, error, no-show, live, future

    useEffect(() => {
        loadNextShow()
    }, [])

    const loadNextShow = async () => {
        try {
            const loadedShow = await showService.getNextShow()
            if (loadedShow.date === 0) return setStatus('no-show')
            setStatus(loadedShow)

        }
        catch (err) {
            console.log(err)
            setStatus('error')
        }
    }

    if (status === 'loading') return <div className="home"><div className="loader"><div></div><div></div><div></div><div></div>
        <div></div><div></div><div></div><div></div></div></div>

    if (status === 'error') return <div className="home"><Error /></div>

    if (status === 'no-show') return <div className="temp">
        <img src={require('../style/imgs/logo.webp')} />
        <p>No shows found.</p>
    </div>

    return <Stream show={status} />
}