import { useState, useEffect } from 'react'
import { make2digits } from '../services/utils'

export function CountDown({ onEnd, showDate }) {
    const [now, setNow] = useState(Date.now())

    const interval = setInterval(() => {
        setNow(Date.now())
    }, 1000)

    useEffect(() => {
        return () => { clearInterval(interval) }
    }, [])

    const timestamp = showDate - now
    let days = Math.floor(timestamp / (1000 * 60 * 60 * 24))
    let hours = Math.floor((timestamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    let minutes = Math.floor((timestamp % (1000 * 60 * 60)) / (1000 * 60))
    let seconds = Math.floor((timestamp % (1000 * 60)) / 1000)

    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
        clearInterval(interval)
        if (onEnd) onEnd()
        return <div className='countdown'>
            <div className='item'>
                <p className='number'>00</p>
                <div className='line' />
                <p className='time'>שניות</p>
            </div>
            <div className='item'>
                <p className='number'>00</p>
                <div className='line' />
                <p className='time'>דקות</p>
            </div>
            <div className='item'>
                <p className='number'>00</p>
                <div className='line' />
                <p className='time'>שעות</p>
            </div>
            <div className='item'>
                <p className='number'>00</p>
                <div className='line' />
                <p className='time'>ימים</p>
            </div>
        </div>
    }

    return <div className='countdown'>
        <div className='item'>
            <p className='number'>{make2digits(seconds)}</p>
            <div className='line' />
            <p className='time'>שניות</p>
        </div>
        <div className='item'>
            <p className='number'>{make2digits(minutes)}</p>
            <div className='line' />
            <p className='time'>דקות</p>
        </div>
        {(hours > 0 || days > 0) && <div className='item'>
            <p className='number'>{make2digits(hours)}</p>
            <div className='line' />
            <p className='time'>שעות</p>
        </div>}
        {days > 0 && <div className='item'>
            <p className='number' >{make2digits(days)}</p>
            <div className='line' />
            <p className='time'>ימים</p>
        </div>}
    </div>
}


