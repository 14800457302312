import { userService } from "../services/userService"
import { useState, useEffect } from "react"
import { Feed } from "../cmps/creator/feed"
import { Premium } from "../cmps/creator/premium"
import { useParams } from "react-router"
import { MagicButton } from "../cmps/magic-button"
import { addFollow, removeFollow } from "../store/reducers/userReducer"
import { formatDateHour } from "../services/utils"
import { useNavigate } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { Error } from "./error"
const options = ['הופעות קרובות', 'מנויים']

export function Creator() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    const [creator, setCreator] = useState('loading')
    const [opt, setOpt] = useState('הופעות קרובות')
    const user = useSelector(state => state.user)

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 })
        loadCreator()
        // dispatch(addSubscription('1234'))
    }, [])

    const loadCreator = async () => {
        try {
            const loadedCreator = await userService.getCreator(id)
            setCreator(loadedCreator)
        }
        catch (err) {
            console.log(err)
            setCreator('error')
        }
    }

    const follow = async () => {
        if (!user.phone) return navigate('/login')
        const creatorId = await userService.follow(creator._id)
        dispatch(addFollow(creatorId))
    }

    const unfollow = async () => {
        const creatorId = await userService.unfollow(creator._id)
        dispatch(removeFollow(creatorId))
    }

    if (creator === 'error') return <div className="home">
        <div className="not-found">
            <img src={require('../style/imgs/error.webp')} alt='error' className="no-history" />
            <h1>העמוד לא ניתן להצגה כרגע...</h1>
            <p>משהו השתבש.</p>
        </div>
    </div>

    if (creator === 'loading') return <div className="home"><div className="loader"><div></div><div></div><div></div><div></div>
        <div></div><div></div><div></div><div></div></div></div>

    try {
        return <section className='creator'>
            <img src={creator.banner} className="banner" />
            <div className="profile-lower relative">
                <img src={creator.image} className="creator-img" />
                <div className="details">
                    <h3>{creator.nickName}</h3>
                    <p className="desc">{creator.description}</p>
                    <div className="social">
                        {creator.socialLinks.youtube && <a href={creator.socialLinks.youtube} target="_blank" rel="noreferrer"><img src={require('../style/imgs/contact/youtube.webp')} /></a>}
                        {creator.socialLinks.instagram && <a href={creator.socialLinks.instagram} target="_blank" rel="noreferrer"><img src={require('../style/imgs/contact/instagram.webp')} /></a>}
                        {creator.socialLinks.tiktok && <a href={creator.socialLinks.tiktok} target="_blank" rel="noreferrer"><img src={require('../style/imgs/contact/tiktok.webp')} /></a>}
                    </div>
                    <p className="followers">{creator.followersAmount} עוקבים</p>
                </div>
                {!user.following.includes(creator._id) ? <MagicButton text="עקוב" classes={'follow'} onClick={follow} /> : <div onClick={unfollow} className="unfollow">הסר עוקב</div>}
            </div>
            <div className="line" />

            <div className="options-bar">
                {options.map(op => <p className={op === opt ? 'chosen' : ''} key={op} onClick={() => setOpt(op)}>{op.charAt(0).toUpperCase() + op.slice(1)}</p>)}
            </div>

            {opt === 'מנויים' ? <div className="premium">
                {user?.subscriptions[id] ? <Feed creatorId={id} /> : <Premium creator={creator} user={user} />}
            </div> :
                !creator.shows.length ? <div className="no-events">
                    <img src={require('../style/imgs/no-results.webp')} />
                    <p>כרגע אין אירועים קרובים של {creator.nickName}</p>
                </div> :
                    <div className="events-container">
                        {creator.shows.map(show => <div className="creator-event" key={show._id} onClick={() => navigate(`/show/${show._id}`)}>
                            <img src={show.img} />
                            <h3>{show.title}</h3>
                            <p>{show.ticketsAmount} צופים בתאריך {formatDateHour(show.date)}</p>
                        </div>)}
                    </div>}
        </section>
    }
    catch (err) {
        console.log(err)
        return <Error />
    }
}