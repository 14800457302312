import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"

import { logout } from "../store/reducers/userReducer"
import { resetGeneralState, setPopup } from "../store/actions/general.actions"

export function Error() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const resetWebsite = () => {
        dispatch(logout())
        dispatch(resetGeneralState())
        navigate('/')
        window.location.reload()
    }
    return <div className='error'>
        <img src={require('../style/imgs/error.webp')} alt='error' />
        <h1>העמוד אינו זמין כעת.</h1>
        <p>אופס! משהו השתבש. נסו לטעון את הדף מחדש.</p>
        <p>אם הדף עדיין אינו זמין צרו קשר עם התמיכה <span onClick={() => { dispatch(setPopup('support')) }}>כאן</span>.</p>
        <div onClick={resetWebsite}>טען מחדש</div>
    </div>
}