import { isMobile } from 'react-device-detect'
import {Wallpaper} from '../cmps/wallpaper.jsx'
const ios = 'https://apps.apple.com/il/app/pikmetv/id6739505062' // change later
const android = 'https://play.google.com/store/apps/details?id=com.anonymous.pikmeTV'

export function Download() {
    const link = /iPhone|iPad|iPod/.test(navigator.userAgent) ? ios : android
    return <div className="temp">
        <Wallpaper />
        <div className='inner'>
            <img src={require('../../src/style/imgs/logo.webp')} />
            {isMobile ? <a href={link}><div className="done undecorate">להורדה בחנות</div></a> : <>
                <p>סרקו את הברקוד כדי להוריד את האפליקציה לנייד :</p>
                <img src={require('../style/imgs/barcode.jpeg')} style={{ width: '400px', height: '400px' }} />
            </>
            }
        </div>
    </div>
}