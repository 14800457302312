
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from 'react-router'
import { userService } from '../services/userService'
import { uploadService } from '../services/upload.service'
import { setNickName, setImage } from '../store/reducers/userReducer'
import { Error } from './error'
import { ErrorServer } from './error-server'
import { setPopup } from '../store/actions/general.actions'
import { ProfileTable } from '../cmps/profile-table'
import { Subscriptions } from '../cmps/subscriptions'

export function Profile() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state) => state.user)
  const [purchases, setPurchases] = useState(null)
  const [nameEdit, setNameEdit] = useState(false)
  const [error, setError] = useState(false)
  const [loader, setLoader] = useState(false)
  const nameRef = useRef()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 })
    document.title = 'PikmeTV | Profile and purchases'
    if (user.nickName) {
      loadPurchases()
      if (!user.personal && Math.random() < 0.33) dispatch(setPopup('personal'))
    }
    else navigate('/login')
  }, [user.isConnected])

  const loadPurchases = async () => {
    try {
      const loadedPurchases = await userService.getUserPurchases()
      const p = Object.values(loadedPurchases)
      p.sort((a, b) => new Date(b.date) - new Date(a.date))
      setPurchases(p)
    }
    catch {
      setError(true)
    }
  }

  const imgChange = async (ev) => {
    try {
      if (ev.target.files[0].size > 1000000) dispatch(setPopup('image-size'))
      setLoader(true)
      const uploadedImage = await uploadService.uploadImg(ev.target.files[0])
      const updatedUser = await userService.updateUser({ image: uploadedImage.secure_url })
      dispatch(setImage(updatedUser.image))
      setLoader(false)
    }
    catch {
      setLoader(false)
      dispatch(setPopup('error-server'))
    }
  }

  const nickNameChange = async (ev) => {
    ev.preventDefault()
    const updatedUser = await userService.updateUser({ nickName: nameRef.current.value })
    dispatch(setNickName(updatedUser.nickName))
    setNameEdit(false)
  }

  if (error) return <ErrorServer />

  try {
    return (
      <section className='profile'>
        <section className='img-holder'>
          <img className='profile-banner' src={require('../style/imgs/home-banner.webp')} alt='profile banner' />
          <img className='user-img noselect' src={user.image} alt='user image profile' />
          <div className='user-img img-cover noselect'>
            <input accept="image/png, image/jpeg, img/webp, img/avix" type="file" id="img" onChange={imgChange} />
            <label htmlFor='img'><span className="material-symbols-outlined clickable">edit</span></label>
            {loader && <div className="loader"><div></div><div></div><div></div><div></div>
              <div></div><div></div><div></div><div></div></div>}
          </div>
        </section>

        <div className='profile-wrapper'>
          <section className='details'>
            <div className='name-holder rtl '>
              <h1>{' ' + user.nickName}<span onClick={() => setNameEdit(!nameEdit)} className="material-symbols-outlined clickable noselect main-color">edit</span></h1>
            </div>
            {nameEdit && <form onSubmit={nickNameChange}>
              <input autoFocus maxLength="15" type='text' placeholder='הכנס שם חדש' ref={nameRef} />
              <button><span className="material-symbols-outlined main-color mirror">chevron_right</span></button></form>}
          </section>
          {/* {purchases ? <ProfileTable shows={Object.values(purchases)} /> : <div className="loader"><div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div></div>}
          <Subscriptions user={user} /> */}
        </div>

      </section>
    )
  }
  catch (err) {
    console.log(err)
    return <Error />
  }
}