import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { setPopup } from "../../store/actions/general.actions"
import { PopupInfo } from "./popup-info"
import { PopupDetails } from "./popup-details"
import { PopupVideo } from "./popup-video"
import { PopupPay } from "./popup-pay"
import { PopupSupport } from "./popup-support"
import { Login } from "../../pages/login"
import { PopupApp } from "./popup-app"
import { EditProfile } from "./popup-edit-profile"

export function Popup() {
    const dispatch = useDispatch()
    const { popup } = useSelector((storeState) => storeState.generalModule)
    const { popupInfo } = useSelector((storeState) => storeState.generalModule)
    const { popupCallback } = useSelector((storeState) => storeState.generalModule)

    useEffect(() => {
        if (popup && popup !== 'info') document.body.classList.add("no-scroll")
        else document.body.classList.remove("no-scroll")
        return () => document.body.classList.remove("no-scroll")
    }, [popup])

    const onAction = () => {
        if (popupCallback) popupCallback()
        dispatch(setPopup(''))
    }

    if (!popup) return <></>

    return (<>
        <div className="screen blur" onClick={() => dispatch(setPopup(''))}>
        </div>
        <section className='popup' style={{ minWidth: (popup === 'purchase-failed' || popup === 'loading' || popup === 'network' || popup === 'details') ? 'fit-content' : '' }}>

            {(popup === 'info' && popupInfo) && <PopupInfo show={popupInfo} />}

            {popup === 'personal' && <PopupDetails />}

            {popup === 'edit-profile' && <EditProfile />}

            {popup === 'login' && <Login component={true}/>}

            {popup === 'pay' && <PopupPay />}

            {popup === 'support' && <PopupSupport />}

            {popup === 'app' && <PopupApp />}

            {popup.includes('cloudinary') && <PopupVideo video={popup} />}

            {popup === 'cancel' && <div className="simple-popup">
                <p>לצערינו ההופעה בוטלה, אתה צפוי לקבל החזר כספי בתום 4 ימי עסקים.</p>
                <p className="main-color" style={{ marginTop: '20px', cursor: 'pointer', fontSize: '24px' }} onClick={() => dispatch(setPopup(''))}>סגור</p>
            </div>}

        </section>
    </>
    )
}