import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { showService } from '../services/showService'
import { setNextShow, setPopup } from '../store/actions/general.actions'
import { Error } from './error'
import { Stream } from './stream'
import { CountDown } from '../cmps/countdown'
import Timer from '../cmps/timer'

export function Home() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((storeState) => storeState.user)
    const { nextShow } = useSelector((storeState) => storeState.generalModule)
    const { popup } = useSelector((storeState) => storeState.generalModule)
    const [status, setStatus] = useState('loading') // loading, error, no-show, future

    useEffect(() => {
        loadNextShow()
    }, [])

    const loadNextShow = async () => {
        try {
            if (nextShow && nextShow.date > Date.now()) return setStatus('future')
            const loadedShow = await showService.getNextShow()
            dispatch(setNextShow(loadedShow))
            if (loadedShow.date === 0) return setStatus('no-show')
            setStatus('future')
        }
        catch (err) {
            console.log(err)
            setStatus('error')
        }
    }

    if (status === 'loading') return <div className="home"><div className="loader"><div></div><div></div><div></div><div></div>
        <div></div><div></div><div></div><div></div></div></div>

    if (status === 'error') return <div className="home"><Error /></div>

    if (status === 'no-shows') return <div className="home">
        <img src={require('../style/imgs/logo.webp')} />
        <p>No live shows in the near future, please check back later.</p>
    </div>

    if (user.phone) return <Stream show={nextShow} user={user} />

    try {
        return <div className='home landing' style={{ backgroundImage: `url(${window.innerWidth > 700 ? nextShow.imgPc : nextShow.img})` }} >
            <div className='half'>
                <img className='logo-vertical' src={require('../style/imgs/logo-vertical.png')} />
                <h1>{nextShow.title}</h1>
                <h3>שידור חי בפלטפורמה בעוד :</h3>
                <CountDown showDate={nextShow.date} />
            </div>
            <div className='half'>
                <div className='btn' onClick={() => navigate('/login')}>אני רוצה להשתתף</div>
                <p className='info'>{nextShow.shortDescription}</p>
            </div>
        </div>
    }
    catch (err) {
        console.log(err)
        return <Error />
    }
}