import React, { useState, useRef } from "react"
import { useSelector } from "react-redux"
// import { StreamChat } from "../cmps/stream-chat"
import { StreamPopup } from "../cmps/stream-popup"
import Timer from "../cmps/timer"
import { CountDown } from "../cmps/countdown"
import { Error } from "./error"
import { useEffect } from "react"
import { httpService } from "../services/http.service"
const AgoraRTC = require('agora-rtc-sdk-ng')
const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" })
AgoraRTC.setLogLevel(2)
const APP_ID = '2148ba0fc4934b56b78fc915f29945f1'
let channelParameters = { localAudioTrack: null, localVideoTrack: null, }
let uid = Math.floor(Math.random() * 10000000)

export function Stream({ show, user }) {
    const [stream, setStream] = useState('paused')
    const [fullScreen, setFullScreen] = useState(false)
    const [popup, setPopup] = useState(false)
    const [isMuted, setIsMuted] = useState(false)
    const [alreadyIn, setAlreadyIn] = useState(false)
    const [showstarted, setshowstarted] = useState(false)
    const { viewers } = useSelector((state) => state.generalModule)
    let reloadInterval = useRef(null)

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 })
        document.documentElement.style.setProperty('--visibility', 'hidden')
        return () => document.documentElement.style.setProperty('--visibility', 'visible')
    }, [])

    useEffect(() => {
        let now = new Date()
        let eventDate = new Date(show.date)
        if (eventDate < now) setshowstarted(true)
        else setshowstarted(false)
        init(show, false)
        return () => cleanup()
    }, [show])

    const cleanup = () => {
        if (channelParameters.localAudioTrack && channelParameters.localAudioTrack != "") {
            channelParameters.localAudioTrack.stop()
            channelParameters.localAudioTrack = ""
        }
        if (channelParameters.localVideoTrack && channelParameters.localVideoTrack != "") {
            channelParameters.localVideoTrack.stop()
            channelParameters.localVideoTrack = ""
        }
        client.remoteUsers.forEach(user => {
            client.unsubscribe(user)
        })
        client.removeAllListeners()
        client.leave()
    }

    const init = async (show, joined) => {
        let channelName = show._id

        try {
            const token = await httpService.get(`handle-stream/${show._id}/${uid}`)
            if (joined) cleanup()
            await client.join(APP_ID, String(channelName), token, uid)
            await client.setClientRole('audience')

            client.on("user-published", async (user, mediaType) => {
                await client.subscribe(user, mediaType)
                if (reloadInterval.current) clearInterval(reloadInterval.current)
                if (mediaType === "video") {
                    channelParameters.localVideoTrack = user.videoTrack
                    setStream('live')
                    channelParameters.localVideoTrack.play("agora_remote")
                    let element = document.getElementsByClassName("agora_video_player")[0]
                    element.controls = true
                }
                if (mediaType === "audio") {
                    channelParameters.localAudioTrack = user.audioTrack
                    channelParameters.localAudioTrack.play()
                }
            })

            client.on("user-unpublished", async (user, mediaType) => {
                await client.unsubscribe(user, mediaType)
                if (mediaType === "audio") {
                    if (channelParameters.localAudioTrack && channelParameters.localAudioTrack != "") {
                        channelParameters.localAudioTrack.stop()
                        channelParameters.localAudioTrack = ""
                        setStream('reconnect')
                        reloadInterval.current = setInterval(() => {
                            setStream('paused')
                        }, 4000)
                    }
                }
                if (mediaType === "video") {
                    if (channelParameters.localVideoTrack && channelParameters.localVideoTrack != "") {
                        channelParameters.localVideoTrack.stop()
                        channelParameters.localVideoTrack = ""
                    }
                }
            })
        }
        catch (e) {
            console.log("join failed", e)
        }
    }

    const getStreamInside = () => {
        if (stream === 'live') return <></>
        if (!showstarted) return <div className='over-video'> <section className="timer-wrapper">
            <CountDown showDate={show.date} onEnd={setshowstarted} />
        </section></div>
        return <section className="over-video">
            {stream === 'over' && <h1>ההופעה הסתיימה</h1>}
            {stream === 'paused' && <>
                <h1>מחכים ליוצר התוכן...</h1>
                <p className="cant-see">אם ההופעה התחילה ואינכם מסוגלים לראות אותה לחצו <span onClick={() => window.location.reload()}>כאן</span>.</p>
            </>}
            {stream === 'reconnect' && <div className="loader"><div></div><div></div><div></div><div></div>
                <div></div><div></div><div></div><div></div></div>}
        </section>
    }

    function muteVideo(mute) {
        let videoElement = document.getElementById('agora_remote')
        if (!videoElement) return
        if (mute) {
            videoElement.muted = true;
            videoElement.volume = 0;
            setIsMuted(true)
        }
        else {
            videoElement.muted = false;
            videoElement.volume = 1;
            setIsMuted(false)
        }
    }

    // if (alreadyIn) return <div className='error'>
    //     <img src={require('../style/imgs/error.webp')} alt='error' />
    //     <h1>שגיאה</h1>
    //     <p>ניתן לצפות בהופעה ממכשיר אחד בלבד בו זמנית.</p>
    //     <p>אנא סגור את האתר בכרטיסיות/מכשירים אחרים ונסה שוב. וודא שלא שלחת לינק להופעה לאף אחד אחר.</p>
    // </div>

    if (!show) return <div className="stream-container"><div className="home"><div className="loader"><div></div><div></div><div></div><div></div>
        <div></div><div></div><div></div><div></div></div></div></div>

    try {
        return <div className="stream-container">
            <div className="stream">
                <div className='stream-video'>
                    <div id='agora_remote' style={{ width: '100%', height: '100%' }} />
                    {getStreamInside()}
                </div>

                <div className="stream-info noselect">
                    <div className="chosen">
                        {/* <img src={show.miniCreator.image} /> */}
                        <div>
                            <h5>{show.title}</h5>
                        </div>
                    </div>
                    <div className="details details-fund" >
                        <div>
                            {isMuted ?
                                <span className="material-symbols-outlined" onClick={() => muteVideo(false)}>volume_off</span>
                                : <span className="material-symbols-outlined" onClick={() => muteVideo(true)}>volume_up</span>}
                            <img src={require('../style/imgs/stream/viewers.webp')} />
                            <p>{viewers}</p>
                        </div>
                    </div>
                </div>
            </div>
            {popup && <StreamPopup fullScreen={fullScreen} setPopup={setPopup} />}
            {/* <StreamChat showId={show._id} setStream={setStream} setFullScreen={setFullScreen} setPopup={setPopup} user={user} setAlreadyIn={setAlreadyIn} /> */}
        </div >
    }
    catch (err) {
        console.log(err)
        return <Error />
    }
}